import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import { guidanceService } from '../../../data/SiteConfig';
import { printGbp, printUsd, gbpToUsd } from '../../utils/formatting';
import ContactForm from '../../components/ContactForm';
import Testimonial from '../../components/Testimonial';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I can help you on an ongoing basis via a Serverless Guidance engagement, designed specifically to assist engineering leaders (CTOs, senior architects, technical team leads) de-risk their team’s or organisation’s serverless adoption.`}</p>
    <h2>{`How it works`}</h2>
    <p>{`After we’ve finished an `}<a parentName="p" {...{
        "href": "/services/"
      }}>{`initial engagement`}</a>{` or custom project together, we will discuss what level of guidance you need going forward to meet your team’s needs and budget.`}</p>
    <p>{`You will get access to me over Slack and (optionally) Zoom on an ongoing monthly basis. If you have any questions, concerns, or anything that you need advice on related to this project, I’m available to answer it within one business day at most, but usually much faster.`}</p>
    <h2>{`What I can help you with`}</h2>
    <p>{`As part of this engagement, I can help you to:`}</p>
    <ul>
      <li parentName="ul">{`Review proposed architectures for a new product or feature.`}</li>
      <li parentName="ul">{`Identify any risks in your current architecture.`}</li>
      <li parentName="ul">{`Make decisions and recommendations between different AWS services.`}</li>
      <li parentName="ul">{`Guide your engineers on development workflows and tools.`}</li>
      <li parentName="ul">{`Perform adhoc code reviews on key product components.`}</li>
      <li parentName="ul">{`Design a CI/CD pipeline process to test and ship your product through to production.`}</li>
    </ul>
    <h2>What clients are saying</h2>
    <section className="mb-8">
      <Testimonial authorName="Allen Green" authorTitle="CTO at Leesman" mdxType="Testimonial">
  <div>
    <p>
      Paul started adding value to the team virtually immediately. I feel the
      upfront discovery work and the report was probably a key part of that. It
      allowed us to agree clear joint objectives and focus in on priority areas
      quickly – something I think we have managed to continue doing, on a
      quarterly basis.
    </p>
    <p>
      One major benefit is the fact that Paul engages, advises, educates and
      unblocks our other devs (both internal and external) virtually on a daily
      basis.
    </p>
    <p>I would 100% recommend Paul&#39;s services. Simply put, he delivers.</p>
  </div>
      </Testimonial>
    </section>
    <h2>{`FAQs`}</h2>
    <h4>{`How much does it cost?`}</h4>
    <p>{`The monthly fee is customised to your project needs based on level of support you require and your budget. I will give you a quote with different options when we have finished our initial engagement.`}</p>
    <h4>{`What are the payment terms?`}</h4>
    <p>{`Payments are monthly in advance. Payment can be made via bank transfer (US & UK only) or credit card.`}</p>
    <h4>{`Do you offer discount for pre-payment of multiple months?`}</h4>
    <p>{`Yes. Discounts are available for paying quarterly (or longer) in advance. Just email me to let me know how many months you require.`}</p>
    <h4>{`Got more questions?`}</h4>
    <p><a parentName="p" {...{
        "href": "/contact/"
      }}>{`Contact me`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      